
  a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: inherit !important;
  }

  #MessageViewBody a {
      color: inherit;
      text-decoration: none;
  }

  p {
      line-height: inherit
  }
  .desktop_hide,
  .desktop_hide table {
      mso-hide: all;
      display: none;
      max-height: 0px;
      overflow: hidden;
  }

  .image_block img+div {
      display: none;
  }

  @media (max-width:768px) {
      .desktop_hide table.icons-inner {
          display: inline-block !important;
      }

      .icons-inner {
          text-align: center;
      }

      .icons-inner td {
          margin: 0 auto;
      }

      .mobile_hide {
          display: none;
      }

      .row-content {
          width: 100% !important;
      }

      .stack .column {
          width: 100%;
          display: block;
      }

      .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
      }

      .desktop_hide,
      .desktop_hide table {
          display: table !important;
          max-height: none !important;
      }

      .row-6 .column-1 .block-1.text_block td.pad,
      .row-6 .column-2 .block-1.text_block td.pad,
      .row-6 .column-3 .block-1.text_block td.pad {
          padding: 0 !important;
      }

      .row-11 .column-2 .block-1.paragraph_block td.pad>div {
          text-align: center !important;
      }

      .row-6 .column-1 {
          padding: 5px 0 5px 10px !important;
      }

      .row-11 .column-1 {
          padding: 10px 0 0 !important;
      }
  }
